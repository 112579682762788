<template>
  <section id="iq-upcoming-movie" style="margin-top: 80px;">
    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div class="iq-main-header d-flex align-items-center justify-content-between">
            <h4 class="main-title">My Videos</h4>
          </div>
          <div class="favorites-contens">
            <!-- <Slick class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction" ref="dSlick" :option="sliderOption"> -->
            <div class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction">
              <li  class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in contents" :key="index">
                <div class="block-images position-relative" @click="gotoPage('/movie-detail/'+item.id)">
                  <div class="img-box">
                    <img :src="item.content_thumbnail" class="img-fluid" alt="">
                  </div>
                  <div class="block-description">
                    <h6 class="iq-title"><a @click="gotoPage('/movie-detail/'+item.id)">{{ item.content_title }}
                    </a></h6>
                    <div class="hover-buttons">
                      <a @click="gotoPage('/movie-detail/'+item.id)" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true"></i>
                        Play Now</a>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- </Slick> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  name: 'PopularShow',
  components: {
  },
  mounted () {
  },
  props: ['contents'],
  data () {
    return {
    }
  },
  methods: {
    gotoPage (link) {
      this.$router.push(link)
    },
    next () {
      this.$refs.dSlick.next()
    },
    prev () {
      this.$refs.dSlick.prev()
    }
  }
}
</script>
